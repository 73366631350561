// src/components/NavBar.tsx
import React, { useState } from 'react';
import { FaTachometerAlt, FaChartBar, FaBook, FaHotjar, FaClipboardCheck, FaSmile, FaLayerGroup, FaPalette, FaUserPlus, FaList, FaSignOutAlt } from 'react-icons/fa';
import { FiMenu, FiX } from 'react-icons/fi';
import Logo from '../assets/MORE LOGO.png';
import { Link, useNavigate } from 'react-router-dom';

const NavBar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const toggleNavBar = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={`bg-white shadow-lg h-screen ${isOpen ? 'w-64' : 'w-20'} transition-width duration-300`}>
            <div className="flex items-center justify-between p-4">
                <div className="text-xl font-semibold text-blue-600">
                    <span className={isOpen ? 'block' : 'hidden'}>
                        <img src={Logo} alt="logo" className="h-8 object-contain" />
                    </span>
                    <span className="block md:hidden">L</span>
                </div>
                <button onClick={toggleNavBar} className="text-gray-600">
                    {isOpen ? <FiX size={24} /> : <FiMenu size={24} />}
                </button>
            </div>
            <div className="mt-10">
                <div className="flex flex-col space-y-4">
                    <NavItem isOpen={isOpen} icon={<FaTachometerAlt />} label="Dashboard" to="/guests" />
                    <NavItem isOpen={isOpen} icon={<FaUserPlus />} label="Add User" to="/add-user" />
                    <NavItem isOpen={isOpen} icon={<FaList />} label="Register Guest" to="/" />
                    {/* <div className={isOpen ? 'mt-6' : 'hidden'}>
                        <p className="px-4 text-sm text-gray-400">CONTENT</p>
                    </div>
                    <NavItem isOpen={isOpen} icon={<FaBook />} label="Guides" />
                    <NavItem isOpen={isOpen} icon={<FaHotjar />} label="Hotspots" notificationCount={20} />
                    <NavItem isOpen={isOpen} icon={<FaClipboardCheck />} label="Checklists" />
                    <NavItem isOpen={isOpen} icon={<FaSmile />} label="NPS" />
                    <div className={isOpen ? 'mt-6' : 'hidden'}>
                        <p className="px-4 text-sm text-gray-400">CUSTOMIZATION</p>
                    </div>
                    <NavItem isOpen={isOpen} icon={<FaLayerGroup />} label="Segments" notificationCount={24} />
                    <NavItem isOpen={isOpen} icon={<FaPalette />} label="Themes" /> */}
                    <NavItem isOpen={isOpen} icon={<FaSignOutAlt />} label="Logout" to="/logout" />

                </div>
            </div>
        </div>
    );
};
const NavItem = ({ isOpen, icon, label, to, notificationCount }: { isOpen: boolean; icon: JSX.Element; label: string; to: string; notificationCount?: number }) => (
    <Link to={to} className="flex items-center p-2 px-4 text-gray-700 hover:bg-blue-50 hover:text-blue-600">
        <div className="mr-4">{icon}</div>
        <span className={isOpen ? 'block' : 'hidden'}>{label}</span>
        {notificationCount && <span className={`ml-auto text-sm font-semibold ${isOpen ? 'block' : 'hidden'}`}>{notificationCount}</span>}
    </Link>
);
export default NavBar;
