// src/pages/SignupPage.tsx
import React from 'react';
import RegistrationForm from '../components/RegistrationForm';
import { Analytics } from '@vercel/analytics/react';
import NavBar from '../components/NavBar';

const SignupPage: React.FC = () => {
    return (
        <div className="flex">
            <NavBar />
            <div className="flex-1 p-4 overflow-auto max-h-screen">
                <div className="mx-auto">
                    <RegistrationForm />
                    <Analytics />
                </div>
            </div>
        </div>
    );
};

export default SignupPage;
