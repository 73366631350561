import toast from "react-hot-toast";
import { db, auth } from "../firebaseConfig";
import { collection, addDoc, getDocs, doc, setDoc, Timestamp } from 'firebase/firestore'
import { createUserWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { EmailService } from './EmailService';

export const createGuestUser = async (name: string, gender: string, visitorType: string = "", company: string | null, email: string, mobile: string, terms: boolean) => {
    const guestUserData = {
        name,
        gender,
        visitorType,
        company,
        email,
        mobile,
        terms,
        createdAt: Timestamp.fromDate(new Date())
    };
    try {

        const docRef = await addDoc(collection(db, "guestUsers"), guestUserData);
        console.log("Document created with ID:", docRef.id);
        
        alert("Guest User created successfully");
        try {
            const emailService = new EmailService();
            await emailService.sendEmail({
                from: "info@more.co.ke",
                to: email,
                subject: "Thank you for showing interest in More",
                html: `<p>Dear ${name},</p> <p>We are honored that you have shown interest in More. We will get back to you shortly.</p> <p>Best Regards, <br> More Classified Team</p>`,
            });
            console.log('Email sent after form submission');
        } catch (error) {
            console.error('Error sending email:', error);
        }
    } catch (e) {
        console.error("Error adding document: ", e);
        toast.error("Error creating Guest User");
    }
}

export const getGuestUsers = async () => {
    try {
        console.log("Fetching guest users...");
        const querySnapshot = await getDocs(collection(db, "guestUsers"));
        console.log("Query snapshot received:", querySnapshot);
        
        const guestUsers = querySnapshot.docs.map(doc => {
            const data = doc.data();
            console.log("Processing document:", doc.id, data);
            return data;
        });
        
        console.log("Guest users list created:", guestUsers);
        return guestUsers;
    } catch (error) {
        console.error("Error fetching guest users:", error);
        return [];
    }
}

// create an auth user
export const createAuthUser = async (email: string, password: string) => {
    try {
        const userCredentials = await createUserWithEmailAndPassword(auth, email, password);
        toast.success("User created successfully");
        alert("User created successfully");
        return userCredentials.user;
    } catch (e) {
        console.error("Error creating user: ", e);
        toast.error("Error creating user");
        throw e;
    }
}

// get all data

