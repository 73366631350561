import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from '../firebaseConfig'; // import auth from Firebase
import { sendPasswordResetEmail, signInWithEmailAndPassword, User } from 'firebase/auth'; // import User type from Firebase
import toast from 'react-hot-toast';

interface AuthContextType {
    user: User | null;
    loading: boolean;
    login: (email: string, password: string) => Promise<void>;
    logout: () => Promise<void>;
    resetPassword: (email: string) => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [user, setUser] = useState<User | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setUser(user);
            setLoading(false);
        });

        return () => unsubscribe();
    }, []);

    const login = async (email: string, password: string) => {
        setLoading(true);
        try {
           await signInWithEmailAndPassword(auth, email, password);
        } catch (error) {
            console.error("Login error", error);
            alert("Error logging in: " + error);
        } finally {
            setLoading(false);
        }
    };

    const logout = async () => {
        setLoading(true);
        try {
            await auth.signOut();
            toast.success("Logged out successfully");
        } catch (error) {
            console.error("Logout error", error);
            toast.error("Error logging out");
        } finally {
            setLoading(false);
        }
    };

    const resetPassword = async (email: string) => {
        try {
            await sendPasswordResetEmail(auth, email);
            toast.success("Password reset email sent");
        } catch (e) {
            console.error("Error sending password reset email: ", e);
            toast.error("Error sending password reset email");
            throw e;
        }
    }

    return (
        <AuthContext.Provider value={{ user, loading, login, logout, resetPassword }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
