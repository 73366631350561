// src/services/emailService.tsx
import { Resend } from 'resend';

interface EmailData {
    from: string;
    to: string;
    subject: string;
    html: string;
}

class EmailService {
    private resend: Resend;
    constructor() {
        this.resend = new Resend(process.env.REACT_APP_RESEND_API_KEY);
    }

    async sendEmail(emailData: EmailData) {
        try {
            await this.resend.emails.send({
                from: emailData.from,
                to: emailData.to,
                subject: emailData.subject,
                html: emailData.html,
            });
        } catch (error) {
            console.error('Failed to send email:', error);
            throw error;
        }
    }
}

const testSendEmail = async () => {
    const emailService = new EmailService();
    const emailData = {
        from: 'onboarding@resend.dev',
        to: 'mjoel4708@gmail.com',
        subject: 'Hello World',
        html: '<p>Hello World</p>',
    };
    await emailService.sendEmail(emailData);
};

export { EmailService, testSendEmail };
