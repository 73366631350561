import React, { useEffect, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import 'chart.js/auto';
import { collection, getDocs, where, orderBy, query, Timestamp } from 'firebase/firestore';
import { Analytics } from '@vercel/analytics/react';
import { db } from '../firebaseConfig';
import NavBar from '../components/NavBar';
interface Guest {
    id: string;
    createdAt: Timestamp;
    name: string;
    email: string;
    visitorType: string;
    company: string;
    mobile: number;
    gender: string;
}
interface QueryFilters {
    startDate?: Date;
    endDate?: Date;
    visitorType?: string;
    gender?: string;
}
const Guests = () => {
    const [guests, setGuests] = useState<{ id: string, createdAt: Timestamp, name: string, email: string, visitorType: string, company: string, mobile: number, gender: string }[]>([]);
    const [chartData, setChartData] = useState<{ labels: string[], datasets: { label: string, data: number[], borderColor: string, backgroundColor: string }[] }>({ labels: [], datasets: [] });
    const [totalGuests, setTotalGuests] = useState(0);
    const [loading, setLoading] = useState(true);
    const [timePeriod, setTimePeriod] = useState('All');
    const [userFilters, setUserFilters] = useState<QueryFilters>({
        startDate: undefined,
        endDate: undefined,
        visitorType: undefined,
        gender: undefined,
    })
    const [activeTab, setActiveTab] = useState<'visitor' | 'exhibitor'>('visitor');

    const fetchGuests = async (filters: QueryFilters = {}) => {
        const { startDate, endDate, visitorType, gender } = filters;

        try {
            console.log("Fetching guests with filters:", filters);

            let q = query(collection(db, "guestUsers"), orderBy("createdAt", "asc"));

            // Apply date filters if provided
            if (startDate) {
                q = query(q, where("createdAt", ">=", startDate));
            }
            if (endDate) {
                q = query(q, where("createdAt", "<=", endDate));
            }

            // Apply visitorType filter if provided
            if (visitorType) {
                q = query(q, where("visitorType", "==", visitorType));
            }

            // Apply gender filter if provided
            if (gender) {
                q = query(q, where("gender", "==", gender));
            }

            const querySnapshot = await getDocs(q);
            console.log("Query snapshot received:", querySnapshot);

            const guestsList = querySnapshot.docs.map(doc => {
                const data = doc.data();
                console.log("Processing document:", doc.id, data);
                return {
                    id: doc.id,
                    createdAt: data.createdAt,
                    name: data.name,
                    gender: data.gender,
                    email: data.email,
                    visitorType: data.visitorType,
                    mobile: data.mobile,
                    company: data.company
                };
            });

            console.log("Guests list created:", guestsList);
            setGuests(guestsList);
            setChartData(getChartData(guestsList, timePeriod));
            setLoading(false);
            console.log("Guests state updated and loading set to false.");
        } catch (error) {
            console.error("Error fetching guests:", error);
            setLoading(false);
        }
    };
    useEffect(() => {
        filterGuestsByDate(timePeriod);
    }, [timePeriod]);

    useEffect(() => {
        fetchGuests(userFilters);
    }, [userFilters]);
    const filterGuestsByDate = (period: string) => {
        const now = new Date();
        let startDate: Date;

        switch (period) {
            case '30 days':
                startDate = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
                setUserFilters({ ...userFilters, startDate, endDate: new Date() });
                break;
            case '7 days':
                startDate = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
                setUserFilters({ ...userFilters, startDate, endDate: new Date() });
                break;
            case '24 hours':
                startDate = new Date(now.getTime() - 24 * 60 * 60 * 1000);
                setUserFilters({ ...userFilters, startDate, endDate: new Date() });
                break;
            case '12 months':
                startDate = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
                setUserFilters({ ...userFilters, startDate, endDate: new Date() });
                break;
            case 'All':
                startDate = new Date(0);
                setUserFilters({ ...userFilters, startDate, endDate: new Date() });
                break;
            default:
                startDate = new Date(0);
                setUserFilters({ ...userFilters, startDate, endDate: new Date() });
                break;
        }
    };
    const getChartData = (guests: Guest[], period: string) => {
        const counts: Record<string, number> = {};
        const timeFormatter = new Intl.DateTimeFormat('en-GB', {
            timeZone: 'Africa/Nairobi',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        });

        guests.forEach((guest) => {
            const date = timeFormatter.format(guest.createdAt.toDate());
            if (!counts[date]) {
                counts[date] = 0;
            }
            counts[date]++;
        });

        const labels = Object.keys(counts).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
        const data = labels.map((label) => counts[label]);

        return {
            labels,
            datasets: [
                {
                    label: 'Guests Registered',
                    data,
                    borderColor: 'rgb(244, 81, 30, 1)',
                    backgroundColor: 'rgba(244, 81, 30, 0.2)',
                    pointBackgroundColor: 'rgb(244, 81, 30, 1)',
                    borderWidth: 2,
                    pointBorderColor: 'rgba(255, 255, 255, 1)',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgba(255, 99, 132, 1)',
                },
            ],
        };
    };

    return (
        <div className="flex">
            <NavBar />
            <div className="flex-1 p-4 overflow-auto max-h-screen">
                <div className="min-h-screen bg-gray-100 p-4">
                    <div className="max-w-7xl mx-auto bg-white shadow-md rounded-md p-6">
                        <h1 className="text-2xl font-semibold mb-4">Guest Analysis</h1>
                        <div className="flex justify-between items-center mb-6">
                            <div>
                                <h2 className="text-xl font-bold">Total Guests: {guests?.length}</h2>
                            </div>
                            <div className="flex space-x-2">
                                <button onClick={() => setTimePeriod('All')} className={`px-4 py-2 rounded-md ${timePeriod === 'All' ? 'bg-gray-400' : 'bg-gray-200'}`}>All</button>
                                <button onClick={() => setTimePeriod('12 months')} className={`px-4 py-2 rounded-md ${timePeriod === '12 months' ? 'bg-gray-400' : 'bg-gray-200'}`}>12 months</button>
                                <button onClick={() => setTimePeriod('30 days')} className={`px-4 py-2 rounded-md ${timePeriod === '30 days' ? 'bg-gray-400' : 'bg-gray-200'}`}>30 days</button>
                                <button onClick={() => setTimePeriod('7 days')} className={`px-4 py-2 rounded-md ${timePeriod === '7 days' ? 'bg-gray-400' : 'bg-gray-200'}`}>7 days</button>
                                <button onClick={() => setTimePeriod('24 hours')} className={`px-4 py-2 rounded-md ${timePeriod === '24 hours' ? 'bg-gray-400' : 'bg-gray-200'}`}>24 hours</button>
                            </div>
                        </div>
                        <div className="bg-gray-100 p-4 rounded-md mb-6" style={{ minHeight: '500px' }}>
                            {loading ? (
                                <div className="animate-pulse flex space-x-4">
                                    <div className="flex-1 space-y-4 py-1">
                                        <div className="h-4 bg-gray-400 rounded w-3/4"></div>
                                        <div className="space-y-2">
                                            <div className="h-4 bg-gray-400 rounded"></div>
                                            <div className="h-4 bg-gray-400 rounded w-5/6"></div>
                                        </div>
                                    </div>
                                </div>
                            ) : chartData.labels.length > 0 ? (
                                <Chart
                                    type="line"
                                    data={chartData}
                                    options={{
                                        scales: {
                                            y: {
                                                beginAtZero: true,
                                                suggestedMax: Math.max(...chartData.datasets[0].data) + 1,
                                                grid: {
                                                    display: true,
                                                    color: 'rgba(200, 200, 200, 0.2)',
                                                },
                                                title: {
                                                    display: true,
                                                    text: 'Number of Guests',
                                                    font: {
                                                        size: 14,
                                                        weight: 'bold',
                                                    },
                                                },
                                            },
                                            x: { // reverse the x-axis
                                                reverse: true,
                                                grid: {
                                                    display: true,
                                                    color: 'rgba(200, 200, 200, 0.2)',
                                                },
                                                title: {
                                                    display: true,
                                                    text: 'Date',
                                                    font: {
                                                        size: 14,
                                                        weight: 'bold',
                                                    },
                                                },
                                            },
                                        },
                                        plugins: {
                                            legend: {
                                                display: true,
                                                position: 'top',
                                            },
                                            tooltip: {
                                                enabled: true,
                                                mode: 'index',
                                                intersect: false,
                                            },
                                        },
                                        elements: {
                                            line: {
                                                tension: 0.4,
                                            },
                                            point: {
                                                radius: 5,
                                                hoverRadius: 7,
                                            },
                                        },
                                        maintainAspectRatio: false,

                                    }}
                                />
                            ) : (
                                <p>No data available for the selected time period</p>
                            )}
                        </div>
                        <div className="bg-white shadow-md rounded-md">
                            <div className="p-4 border-b">
                                <h2 className="text-lg font-semibold">Guests </h2>
                                <div className="mt-4">
                                    <button
                                        onClick={() => setActiveTab('visitor')}
                                        className={`px-4 py-2 rounded-tl-md rounded-tr-md ${activeTab === 'visitor' ? 'bg-gray-200 font-semibold' : 'bg-gray-100'}`}
                                    >
                                        Visitors
                                    </button>
                                    <button
                                        onClick={() => setActiveTab('exhibitor')}
                                        className={`px-4 py-2 rounded-tl-md rounded-tr-md ${activeTab === 'exhibitor' ? 'bg-gray-200 font-semibold' : 'bg-gray-100'}`}
                                    >
                                        Exhibitors
                                    </button>
                                </div>
                            </div>
                            {loading ? (
                                <div className="animate-pulse">
                                    <div className="h-4 bg-gray-400 rounded mb-4"></div>
                                    <div className="h-4 bg-gray-400 rounded mb-4"></div>
                                    <div className="h-4 bg-gray-400 rounded mb-4"></div>
                                    <div className="h-4 bg-gray-400 rounded mb-4"></div>
                                </div>
                            ) : (
                                <div className="h-74 overflow-y-auto mt-4">
                                    {guests.map((guest) => (
                                        <div key={guest.id} className="p-4 flex justify-between items-center border-b">
                                            <div>
                                                <h3 className="text-md font-medium">{guest.name}</h3>
                                                <p className="text-sm text-gray-600">{guest.email}</p>
                                            </div>
                                            <div className="text-sm text-gray-600">{guest.mobile}</div>
                                            <div className="text-sm text-gray-600">{guest.gender}</div>
                                            {activeTab === 'exhibitor' && (
                                                <div className="text-sm text-gray-600">{guest.company}</div>
                                            )}
                                            <div>
                                                <p className="text-sm text-gray-600">
                                                    {new Intl.DateTimeFormat('en-GB', { timeZone: 'Africa/Nairobi' }).format(guest.createdAt.toDate())}
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Analytics />
        </div>
    );
};

export default Guests;
