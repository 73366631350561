// src/context/FirebaseContext.tsx
import React, { createContext, useContext, ReactNode } from "react";
import { auth, db } from "../firebaseConfig";

interface User {
  uid: string;
  email: string;
  name: string;
}
const FirebaseContext = createContext({ auth, db });

export const FirebaseProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <FirebaseContext.Provider value={{ auth, db }}>
      {children}
    </FirebaseContext.Provider>
  );
};

export const useFirebase = () => useContext(FirebaseContext);
