// src/components/RegistrationForm.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import BannerImage from '../assets/moreBannerImage.jpg';
import SaritImage from '../assets/sarit.jpg';
import MarwaImage from '../assets/MARWA_HOMES_LTD.png';
import MoreImage from '../assets/MORE LOGO.png';
import Faras from '../assets/faras.png';
import Macaash from '../assets/macaash.png';
import Rexpo from '../assets/Rexpo.jpg';
import Video from '../assets/videoFile.mp4';
import { createGuestUser } from '../services/GuestUserServices';
import toast from 'react-hot-toast';

const RegistrationForm: React.FC = () => {
    const saveGuestDetails = async (values: any) => {
        try {
            // Save the guest details to the database
            console.log('Guest details:', values);
        } catch (error: any) {
            alert('Error saving guest details: ' + error.message);
        }
    }
    const formik = useFormik({
        initialValues: {
            name: '',
            gender: '',
            visitorType: '',
            companyName: '',
            email: '',
            mobile: '',
            terms: false,
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Name is required'),
            gender: Yup.string().required('Gender is required'),
            visitorType: Yup.string().required('Visitor type is required'),
            companyName: Yup.string(),
            email: Yup.string().email('Invalid email address').required('Email is required'),
            mobile: Yup.string().matches(/^[0-9]+$/, 'Must be only digits').required('Required'),
            terms: Yup.boolean().oneOf([true], 'You must accept the terms and conditions').required('Required'),
        }),
        onSubmit: async (values, helpers) => {
            helpers.setSubmitting(true);
            try {
                await createGuestUser(values.name, values.gender, values.visitorType, values.companyName, values.email, values.mobile, values.terms);
                toast.success('Guest details saved successfully');
                alert('Guest details saved successfully');
                formik.resetForm();
            } catch (error: any) {
                 console.log(error)
                toast.error('Error saving guest details: ' + error.message);
            } finally {
                helpers.setSubmitting(false);
            }
        }
    });

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="bg-white shadow-md rounded-md flex w-11/12 max-w-4xl">
                {/* Left Side - Form */}
                <div className="w-full lg:w-1/2 p-8">
                    <h1 className="text-3xl font-bold mb-2">Guest registration form</h1>
                    <p className="text-gray-600 mb-6">Fill the guest registration form to get started.</p>
                    <form onSubmit={formik.handleSubmit} className="space-y-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Representative Name</label>
                            <input
                                type="text"
                                {...formik.getFieldProps('name')}
                                className={`mt-1 block w-full p-2 border ${formik.touched.name && formik.errors.name ? 'border-red-500' : 'border-gray-300'
                                    } rounded-md`}
                                placeholder="Enter your name"
                            />
                            {formik.touched.name && formik.errors.name ? (
                                <div className="text-red-500 text-sm">{formik.errors.name}</div>
                            ) : null}
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Gender</label>
                            <select
                                {...formik.getFieldProps('gender')}
                                className={`mt-1 block w-full p-2 border ${formik.touched.gender && formik.errors.gender ? 'border-red-500' : 'border-gray-300'
                                    } rounded-md`}
                            >
                                <option value="" disabled>
                                    Select a gender
                                </option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="other">Other</option>
                                <option value="prefer not to say">Prefer not to say</option>
                            </select>
                            {formik.touched.gender && formik.errors.gender ? (
                                <div className="text-red-500 text-sm">{formik.errors.gender}</div>
                            ) : null}
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Visitor Type</label>
                            <select
                                {...formik.getFieldProps('visitorType')}
                                className={`mt-1 block w-full p-2 border ${formik.touched.visitorType && formik.errors.visitorType ? 'border-red-500' : 'border-gray-300'
                                    } rounded-md`}
                            >
                                <option value="" disabled>
                                    Select a visitor type
                                </option>
                                <option value="visitor">Visitor</option>
                                <option value="exhibitor">Exhibitor</option>
                                <option value="other">Other</option>
                            </select>
                            {formik.touched.visitorType && formik.errors.visitorType ? (
                                <div className="text-red-500 text-sm">{formik.errors.visitorType}</div>
                            ) : null}
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Company From</label>
                            <input
                                type="text"
                                {...formik.getFieldProps('companyName')}
                                className={`mt-1 block w-full p-2 border ${formik.touched.companyName && formik.errors.companyName ? 'border-red-500' : 'border-gray-300'
                                    } rounded-md`}
                                placeholder="Enter your company name"
                            />
                            {formik.touched.companyName && formik.errors.companyName ? (
                                <div className="text-red-500 text-sm">{formik.errors.companyName}</div>
                            ) : null}
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Email Address</label>
                            <input
                                type="email"
                                {...formik.getFieldProps('email')}
                                className={`mt-1 block w-full p-2 border ${formik.touched.email && formik.errors.email ? 'border-red-500' : 'border-gray-300'
                                    } rounded-md`}
                                placeholder="Enter your email address"
                            />
                            {formik.touched.email && formik.errors.email ? (
                                <div className="text-red-500 text-sm">{formik.errors.email}</div>
                            ) : null}
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Mobile Number</label>
                            <input
                                type="tel"
                                {...formik.getFieldProps('mobile')}
                                className={`mt-1 block w-full p-2 border ${formik.touched.mobile && formik.errors.mobile ? 'border-red-500' : 'border-gray-300'
                                    } rounded-md`}
                                placeholder="Enter your mobile number"
                            />
                            {formik.touched.mobile && formik.errors.mobile ? (
                                <div className="text-red-500 text-sm">{formik.errors.mobile}</div>
                            ) : null}
                        </div>
                        <div className="flex items-center">
                            <input
                                type="checkbox"
                                {...formik.getFieldProps('terms')}
                                className={`h-4 w-4 text-orange-600 border ${formik.touched.terms && formik.errors.terms ? 'border-red-500' : 'border-gray-300'
                                    } rounded`}
                            />
                            <label className="ml-2 block text-sm text-gray-900">
                                I agree to the{' '}
                                <a href="/terms" className="text-orange-600 hover:text-orange-800">
                                    terms and conditions
                                </a>
                            </label>
                        </div>
                        {formik.touched.terms && formik.errors.terms ? (
                            <div className="text-red-500 text-sm">{formik.errors.terms}</div>
                        ) : null}
                        <button
                            type="submit"
                            className="w-full py-2 px-4 bg-orange-600 text-white font-semibold rounded-md hover:bg-orange-700"
                            disabled={formik.isSubmitting}
                        >
                            {formik.isSubmitting ? 'Submitting...' : 'Submit'}
                        </button>
                    </form>
                    {/* <div className="mt-4 text-center">
                        <Link to="/login" className="text-sm text-gray-600 hover:text-gray-800">
                            Already have an account? Login
                        </Link>
                    </div> */}
                </div>
                {/* Right Side - Image */}
                <div className="hidden md:block w-1/2 h-full p-4">
                    <div className="relative w-full h-0 pb-[120.25%] overflow-hidden"> {/* 16:9 aspect ratio */}
                        <video className="absolute inset-0 w-full h-full object-cover" autoPlay muted loop>
                            <source src={Video} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    {/* Logos rows */}
                    <h6 className="text-2xl italic mt-4">Partners</h6>
                    <div className="flex flex-wrap justify-start mt-2 space-x-4 w-full">
                        <img src={SaritImage} alt="Logo" className="w-20 h-20 rounded-full" />
                        <img src={MarwaImage} alt="Logo" className="w-20 h-20 rounded-full" />
                        <img src={Faras} alt="Logo" className="w-20 h-20 rounded-full" />
                        <img src={Macaash} alt="Logo" className="w-20 h-20 rounded-full" />
                        <img src={Rexpo} alt="Logo" className="w-20 h-20 rounded-full" />
                        <img src={MoreImage} alt="Logo" className="w-30 h-20 rounded-full object-contain" />
                        
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegistrationForm;
