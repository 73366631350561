import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';
import TermsPage from './pages/TermsPage';
import Guests from './pages/GuestsPage';
import { AuthProvider } from './context/AuthContext';
import AddUserPage from './pages/AddUserPage';
import ProtectedRoute from './ProtectedRoute';
import ResetPasswordPage from './pages/ResetPasswordPage';
import LogoutPage from './pages/LogoutPage';

const AppRouter: React.FC = () => {
    return (
        <AuthProvider>
            <Router>
                <Routes>
                    <Route
                        path="/"
                        element={<SignupPage />}
                    />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/logout" element={<LogoutPage />} />
                    <Route 
                        path='/reset-password' 
                        element={<ResetPasswordPage />}
                    />
                    <Route
                        path="/add-user"
                        element={
                            <ProtectedRoute component={AddUserPage} />
                        }
                    />
                    <Route path="/terms" element={<TermsPage />} />
                    <Route
                        path="/guests"
                        element={
                            <ProtectedRoute component={Guests} />
                        }
                    />
                    <Route path="*" element={<div>Not Found</div>} />
                </Routes>
            </Router>
        </AuthProvider>
    );
};

export default AppRouter;
