import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const LogoutPage: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const { logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const performLogout = async () => {
      try {
        await logout();
        // Simulate a delay for UX feedback
        setTimeout(() => {
          setLoading(false);
          // Redirect to login page after a short delay
          setTimeout(() => {
            navigate('/login');
          }, 1500);
        }, 1500);
      } catch (error) {
        alert('Error during logout: ' + (error as Error).message);
        setLoading(false);
      }
    };

    performLogout();
  }, [logout, navigate]);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center p-4">
      <div className="max-w-md w-full space-y-8 bg-white p-8 rounded-lg shadow-md">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Logging Out</h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            {loading ? 'Please wait while we log you out...' : 'You have been successfully logged out.'}
          </p>
        </div>
        <div className="mt-8 flex justify-center">
          {loading ? (
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-orange-500"></div>
          ) : (
            <svg className="h-12 w-12 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
            </svg>
          )}
        </div>
      </div>
    </div>
  );
};

export default LogoutPage;