import React from 'react';
import { Link } from 'react-router-dom';

const TermsPage: React.FC = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 p-4">
      <div className="bg-white shadow-md rounded-md w-full max-w-4xl p-8">
        <h1 className="text-3xl font-bold mb-4">Terms and Conditions</h1>
        <div className="space-y-4 text-gray-700">
          <p>Welcome to our website! By using our site, you agree to the following terms and conditions:</p>
          <h2 className="text-2xl font-semibold">1. Use of Website</h2>
          <p>This website is intended for personal and non-commercial use only. You agree to use this website only for lawful purposes.</p>
          
          <h2 className="text-2xl font-semibold">2. Intellectual Property</h2>
          <p>All content on this website, including text, graphics, logos, and images, is the property of our company and is protected by copyright and other intellectual property laws.</p>

          <h2 className="text-2xl font-semibold">3. Privacy</h2>
          <p>We are committed to protecting your privacy. Please refer to our Privacy Policy for information on how we collect, use, and protect your personal information.</p>

          <h2 className="text-2xl font-semibold">4. Limitation of Liability</h2>
          <p>We are not liable for any damages arising from the use of this website or the inability to use this website. This includes direct, indirect, incidental, punitive, and consequential damages.</p>

          <h2 className="text-2xl font-semibold">5. Changes to Terms</h2>
          <p>We reserve the right to modify these terms and conditions at any time. Your continued use of this website constitutes your acceptance of any changes to these terms.</p>
          
          <p>If you have any questions about these terms and conditions, please <Link to="/contact" className="text-orange-600 hover:text-orange-800">contact us</Link>.</p>
        </div>
        <div className="mt-4">
          <Link to="/" className="text-sm text-gray-600 hover:text-gray-800">Go back to Registration Form</Link>
        </div>
      </div>
    </div>
  );
};

export default TermsPage;
